<template>
  <div class="privacy">
      <div class="text">
        <div class="box">
        <p style="margin:0pt 0pt 18pt;text-indent:0"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:18pt; font-weight:bold">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:18pt; font-weight:bold"> 隐私政策</span></p>
        <p style="margin:0pt 0pt 12.75pt"><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">生效日期：20</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">20</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">年</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">12</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">月</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">1</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">日</span></p>
        <p style="margin:0pt 0pt 12.75pt"><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">发布日期：20</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">20</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">年</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">12</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">月</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">15</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">日</span></p>
        <p style="margin:0pt 0pt 12.75pt"><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">更新日期：2020年1</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">2</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">月</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">1</span><span
                style="color:#555555; font-family:微软雅黑; font-size:13.5pt">日</span></p>
        <p style="margin:0pt 0pt 19.5pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">一、【用户个人信息保护与隐私保护】</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">1.1 保护用户个人信息是</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">四川月见初花文化传媒有限公司</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">的一项基本原则。</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">四川月见初花文化传媒有限公司</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">视用户信息安全与隐私保护为自己的"生命线"。我们致力于提升信息处理透明度，增强您对信息管理的便捷性，保障您的信息及通信安全。严格遵守法律法规，遵循以下隐私保护原则，为您提供更加安全、可靠的服务：</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）安全可靠：我们竭尽全力通过合理有效的信息安全技术及管理流程，防止您的信息泄露、损毁、丢失；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）保护通信秘密：我们严格遵照法律法规，保护您的通信秘密，为您提供安全的通信服务；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）合理必要：为了向您和其他用户提供更好的服务，我们仅收集必要的信息；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4）清晰透明：我们努力使用简明易懂的表述，向您介绍隐私政策，以便您清晰地了解我们的信息处理方式；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（5）将隐私保护融入产品设计：我们在产品或服务开发的各个环节，综合法律、产品、设计的多方因素，融入隐私保护的理念。</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">&#xa0;</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">1.2
                您在注册账号或使用本服务的过程中，可能需要填写一些必要的信息（详见第二点，我们可能会收集的信息）。根据国家或地区法律法规的特殊规定，您可能需要填写真实的身份信息。若您填写的真实信息不完整，则可能在使用服务过程中受到限制。一般而言，我们仅为实现目的所必需的最短时间保留您的个人信息。当我们的产品或服务发生停止运营的情形时，我们将采取通知或公告的形式通知您，并在合理的期限内删除或匿名化处理您的个人信息；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">个人信息存储和超期处理方式：我们在中华人民共和国境内运营中收集和产生的个人信息，均存储在中国境内；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">存储期限：日志信息不少于6个月、交易信息在交易完成日期不少于三年，记录备份不少于60日，除非法律有强制的存留要求；在您的个人信息超出保留期间后，我们会根据适用的反馈要求删除您的个人信息、或使其匿名化处理。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">1.3
                一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">1.4 </span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">四川月见初花文化传媒有限公司</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">非常重视对未成年人个人信息的保护。根据相关法律法规的规定，若您是18周岁以下的未成年人，在使用我们的的服务前，应事先取得您的家长或法定监护人的同意。
                若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过网站中的联系方式与我们联系。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">1.5 未经您的同意，</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">不会向</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">以外的任何公司、组织和个人披露您的个人信息，保证不对外公开或向第三方披露或提供用户注册资料及用户在使用网络服务时存储在</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">平台、服务器或数据库的非公开内容和信息，但下列情况除外：</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）事先获得用户的明确授权；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）根据有关的法律法规要求；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）按照相关政府主管部门和司法机关的要求；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4）为维护社会公众的利益；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（5）为执行相关服务协议或为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">1.6
                因用户使用第三方服务或者设备，可能导致用户信息通过其他方式透露给第三方，用户需自行了解第三方对用户信息保护的相关条款，</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">不承担由此产生的风险。上述情形包括：用户在移动设备上使用第三方应用程序；因第三方应用程序收集的用户信息可能发送至第三方进行处理。</span>
        </p>
        <p style="line-height:18pt; margin:0pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">1.7您理解并同意：如您是通过QQ号码或微信帐号开通的本服务，则关于您的QQ号码的获取、使用、找回及微信帐号的的注册、登录、使用操作请您参考《QQ号码规则》（链接地址：</span><a
                style="color:#555555" href="http://zc.qq.com/chs/agreement1_chs.html"><span
                    style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; text-decoration:underline">http://zc.qq.com/chs/agreement1_chs.html</span></a><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">）及《腾讯微信使用条款和隐私政策》（链接地址：</span><a
                style="color:#555555"
                href="http://support.weixin.qq.com/cgi-bin/mmsupport-bin/readtemplate?stype=&amp;promote=&amp;fr=&amp;lang=zh_CN&amp;ADTAG=&amp;check=false&amp;nav=faq&amp;t=weixin_agreement"><span
                    style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; text-decoration:underline">http://support.weixin.qq.com/cgi-bin/mmsupport-bin/readtemplate?stype=&amp;promote=&amp;fr=&amp;lang=zh_CN&amp;ADTAG=&amp;check=false&amp;nav=faq&amp;t=weixin_agreement</span></a><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">）注：链接地址可能会发生改变，以QQ和微信官方信息为准。</span>
        </p>
        <p style="margin:0pt 0pt 19.5pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">二、【我们可能会收集的信息】</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。我们可能会收集、储存和使用下列与您有关的信息。对本协议的同意将被视为同意我们收集您的个人信息，并在法律规定及本协议约定的范围内进行使用。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">2.1 我们收集以下各类信息：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">2.1.1 账号服务所需信息：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(1) 您在注册账户时填写的信息：</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">手机号码*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">电子邮箱地址*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(2)
                我们可能会获得您在使用第三方合作伙伴的账号登录时，第三方向我们披露的信息，包括：用户名、头像。对于这部分内容，请您仔细阅读第三方合作伙伴服务的用户协议或隐私政策；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(3) 您在编辑资料时填写的信息：头像、昵称；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(4) 个人账户资料显示：头像、昵称、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">应用内虚拟币余额*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">充值记录*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">消费记录*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">&#xa0;</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(5) 实名认证:
                您通过「实名认证」要求向我们提供您相应的身份信息*：</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">身份证*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">护照*及其他身份证件信息*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(6)
                账户安全：我们需要收集您的一些信息来保障您的账号安全、判断您的账户及交易风险，包括如下信息：账号</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">登录地*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">登录IP地址*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、登录时间、机型、网络类型、设备唯一标识符（IMEI、IMSI、设备MAC地址、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">AndroidID</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">）、屏幕分辨率及大小、系统版本号、产品版本号、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">交易信息*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">实名认证信息*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、应用列表信息；一旦我们检测出存在或疑似存在账号安全风险时，我们会使用相关信息进行安全验证与风险排除，确保我们向您提供的产品和服务的安全性，以用来保障您的权益不受侵害。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">2.1.2 产品或服务所需信息</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(1)
                文字书及漫画阅读、听书功能：为了能够更好的为您提供小说阅读服务，提升阅读体验、优化小说展示效果，我们需要收集如下信息：设备型号、设备品牌、操作系统和应用程序版本及来源渠道、网络类型、登录</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">IP地址*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">日志信息*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（操作日志、服务日志、开发日志、闪退日志）；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(2)
                搜索功能：当您使用搜索功能时，我们需要收集您搜索的字词、浏览记录，以提供给您更好的搜索结果、同时改进我们的产品服务；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(3) 个性化推荐及推送功能：
                为了能够更好地为您推荐作品，我们可能收集您的个人信息, 包括：性别、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">阅读偏好*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（您主动填写的喜欢的作品分类信息）、阅读记录、浏览行为、加书架行为、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">交易记录*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">设备唯一标识符*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（IMEI、IMSI、ICCID、设备MAC地址、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">AndroidID</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、安卓广告ID）、应用启动关闭信息、应用列表信息，以汇集、分析单独的或与其他信息结合进行使用，以便于向您提供个性化服务与推送；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(4)
                评论、问答类信息发布功能：当您使用评论、配音、漫画弹幕、发帖功能时，我们将收集您主动提供的图文、音频、视频信息；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(5)
                客服/用户反馈功能：当您通过客服进行咨询、账号申诉功能时，我们可能需要收集您的账号信息以验证您的身份，也有可能收集您反馈意见和建议时填写的</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">手机号码*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、QQ号、微信号；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">(6) 您参加我们举办的活动时所提交的信息：</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">姓名*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">手机号码*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">收货地址*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">*
                我们的部分服务可能需要您提供特定的个人敏感信息（本隐私政策中标注*）来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">*
                个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。例如，个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信内容、健康生理信息等。本隐私政策中的“个人信息”或“个人敏感信息”所包含的内容出自</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">于GB/T35273《个人信息安全规范》，定义与《个人信息安全规范》中的定义一致。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">2.2 根据用户行为及产品特点，</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">可能访问用户手机上的以下内容：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">2.2.1
                对于使用安卓系统的用户，您在使用我们向您提供的相关产品时，为提供、处理、维护、改善、开发我们的商品和/或提供给您的服务之目的，该产品可能获取您终端设备的如下权限：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）用户在下载图书将图书数据存入SD卡内以及在接收推送服务存储用户信息时，需使用SD卡写入/删除内部存储权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）用户阅读已下载图书读取图书数据以及在接受推送存储用户信息时，需读取内部存储的权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）为确认下载图书数据存放方式需判断用户是否插入SD卡而使用挂载文件系统权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4）为计算存储空间的空闲区域而使用获取应用大小权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（5）用户使用语音朗读功能时，为保证来电时停止语音朗读而读取手机状态和身份；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（6）角色配音功能和句子配音需要用到录制音频权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（7）为用户上传头像时可以选择使用摄像机拍照而使用拍摄照片和视频、访问闪光灯权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（8）为使用本软件联网功能而使用访问网络权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（9）为判断用户当前网络状态以便提示用户而获取用户网络状态；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（10）提示用户打开网络而使用改变网络状态权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（11）用户进行下载图书操作时，当用户当前网络是2G/3G/4G时，为节约用户流量提示其打开</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">wifi</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">网络而使用获取、改变</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">wifi</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">状态权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（12）为用户阅读时可以改变横竖屏方向而申请改变系统设置权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（13）为用户调节手机亮度以及存取唯一用户ID而读写系统设置项；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（14）为保证用户能第一时间获得通知、保证听书功能可以正常使用而允许程序开机自动运行、允许程序在手机屏幕关闭后后台进程仍然运行；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（15）为发生异常情况时重启本软件而使用结束系统任务权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（16）为在手机桌面创建快捷方式而使用排序系统任务；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（17）为在Android
                7.1系统及以上实现</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">ShortCut</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">快捷方式功能，需要创建桌面快捷方式权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（18）用户使用语音朗读功能和配音播放时为调节音量大小而使用修改声音设置权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（19）为给用户手机发送图书更新通知时手机震动而使用允许振动权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（20）为帮助用户解决本软件发生异常时的问题而读取系统日志；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（21）为监测用户将图书分享到其他应用功能是否正常而使用允许程序获取当前或最近运行的应用权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（22）为实现悬浮窗功能而使用显示系统窗口权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（23）真人听书功能需要在锁屏时通过指纹快速解锁手机而使用指纹解锁权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（24）为使用户在全屏阅读时，阅读页面显示电池电量信息而使用电池状态权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（25）为支持阅读常亮功能、保持网络链接通畅，需监听屏幕状态、获取屏幕常亮权限，使系统不会因为用户长时间不操作而关闭屏幕；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（26）为允许用户通过蓝牙耳机使用真人听书功能，当蓝牙断开连接时自动暂停播放音频，而使用创建蓝牙连接权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（27）为了允许用户通过蓝牙耳机使用真人听书功能以及个推服务中应景推送，需要使用蓝牙管理权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（28）接收Android系统内部发送过来的广播而允许一个程序收到广播后快速收到下一个广播；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（29）为了允许应用出现闪退后清楚本身进程重启，需要杀死后台进程功能；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（30）为支持直播功能需获得重新设置外拨电话的路径；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（31）为了用户使用漫画下载功能、听书功能而允许本程序的漫画下载功能、听书功能运行过程中避免被系统结束进程；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（32）为了用户在本程序内浏览网页而允许程序自动开启“腾讯X5内核”帮助提高浏览网页的流畅度。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">2.2.2
                对于使用iOS的用户，您在使用本软件时，为提供、处理、维护、改善、开发我们的商品和/或提供给您的服务之目的，本软件可能获取您终端设备的如下权限：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）为使用播放功能或配音功能时获取用户声音之目的调取麦克风权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）为实现用户上传头像功能以及发帖功能，可能需要使用手机相机权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）为允许用户保存图片，可能需要使用相册权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4）为实现软件正常运行，需要使用网络权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（5）保证听书功能可以正常使用而允许程序在手机屏幕关闭后后台进程仍然运行；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（6）为获取系统广告标识用于更精准地推荐，可能需要使用允许广告跟踪权限。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">2.2.3 第三方SDK接入服务所需开通的权限</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">我们的产品和服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。在第三方向您提供产品和服务时，可能会获取到以下权限：</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）用户下载文档时，因需将数据存入SD卡内第三方SDK腾讯视频、百度TTS需写入/删除内部存储权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）用户打开存储文档时，因需读取文档数据，第三方SDK腾讯视频、百度TTS需读取内部存储权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）第三方SDK腾讯直播为确认下载文档数据存放方式需判断用户是否插入SD卡而使用挂载文件系统权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4）用户使用语音朗读功能时，为保证来电时停止语音朗读，以及推送服务生成唯一标识时，需读取手机状态和身份；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（5）为使用本软件联网功能而使用访问网络权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（6）为判断用户当前网络状态以便提示用户而获取用户网络状态；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（7）提示用户打开网络而使用改变网络状态权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（8）用户进行下载操作时，当用户当前网络是2G/3G/4G时，为节约用户流量，第三方SDK新浪、银联支付会提示其打开</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">wifi</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">网络，采集用户地理信息而使用获取、改变</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">wifi</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">状态权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（9）为用户调节手机亮度而读写系统设置项；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（10）第三方SDK腾讯直播为给用户手机发送更新通知时手机震动而使用允许振动权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（11）为监测用户分享到其他应用功能是否正常，第三方SDK支付宝、银联、腾讯云、极光推送、个推推送、VIVO推送需使用允许程序获取当前或最近运行的应用权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（12）当用户在设置阅读界面常亮，需要使用保持屏幕常亮权限，使系统不会因为用户长时间不操作而关闭屏幕；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（13）第三方SDK银联支付、极光推送、个推推送、广点通需通过用户当前的</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">位置*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">来进行相关决策，需获取粗略</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">位置*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">及</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">位置*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（14）为支持银联NFC支付功能，需获取近距离通讯操作权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（15）为接受极光发来的消息，需获取极光推送权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（16）为支持OPPO手机上的推送功能，需获取OPPO推送自定义权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（17）为支持小米手机上的推送功能，需获取小米推送自定义权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（18）安装APK权限：接入第三方SDK广点通所需权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（19）为支持推送能力，需获得访问额外的</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">位置*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">提供程序命令权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（20）为使用第三方基础图片加载库Glide,
                需获取读写通讯录联系人的权限；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（21）获取应用自动运行权限用于：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（a）第三方SDK“腾讯Tinker热补丁”为保证本软件发生异常崩溃问题可支持线上修复而允许程序通过动态下发补丁，修复出现的问题。为保证正确应用补丁、避免合并出错而允许程序在修复过程中避免系统结束进程；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（b）第三方SDK“</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">qapm</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">”为发生异常情况时采集程序的异常现场、保证日志上传而允许程序在上报日志的过程中避免系统结束进程；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（c）第三方SDK“腾讯直播”为用户使用直播功能而允许本进程在运行过程中避免被系统结束进程；为保证用户能第一时间获得通知，第三方SDK腾讯直播需获取允许程序开机自动运行权限；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（d）第三方SDK“百度</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">tts</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">”为了用户使用语音朗读功能而允许本程序运行过程中避免被系统结束进程；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（e）第三方SDK“极光推送”为保证用户第一时间获得活动通知而允许本进程开机自动运行、允许在收集屏幕关闭后，后台进程仍在运行；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（f）第三方SDK“广点通”为向用户提广告服务、下载广告资源而允许程序自动运行；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（g）第三方SDK“</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">QuestMobile</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">”为进行数据统计分析，通过自动化工具（如代码、接口、SDK等）嵌入或接入我们的产品收集您的手机品牌、手机型号、系统版本、App软件列表，以及加密后的国际移动设备码（IMEI）、安卓ID和iOS广告标识符（IDFA）、IP地址；</span>
        </p>
        <p style="line-height:18pt; margin:0pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（22）为支持由浙江每日互动网络科技股份有限公司提供推送技术服务，我们可能会收集您的设备平台、设备厂商、设备品牌、设备唯一识别符等设备信息，应用列表信息、网络状态以及</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">位置*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">相关信息，并提供给浙江每日互动网络科技股份有限公司，用于为您提供推送技术服务，详细内容请访问</span><a
                style="color:#555555" href="http://docs.getui.com/privacy"><span
                    style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; text-decoration:underline">《个推隐私政策》</span></a><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（http://docs.getui.com/privacy）。</span>
        </p>
        <p style="margin:0pt 0pt 19.5pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">三、【我们可能如何使用信息】</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.1 我们可能如何使用信息</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.1.1用户同意</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">基于下列原因需要使用用户的信息资源：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）向您提供核心阅读服务；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）安全保障。我们会将您的信息用于身份验证、安全防范、反诈骗监测、存档备份、客户的安全服务中；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）产品的开发和优化。当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的服务；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4）使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，包括个性化推荐书籍、个性化推送；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（5）执行软件，进行软件认证或软件升级；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（6）让您参与有关我们产品和服务的调查；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（7）向您推荐您可能感兴趣的广告、资讯、评估，进一步改善我们的广告投放和其他促销及推广活动的效果；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（8）在用户同意的情况下，将根据用户信息所作出的统计数据、行为分析非关联用户身份识别的信息提供给合作单位，用于呈现合作效果；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（9）为用户推送通知及</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">提供的服务信息；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（10）修改全局系统设置；更改屏幕显示方向；使用模拟地点来源进行测试；编辑短信或彩信。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.1.2涉及个人信息使用的功能列表：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）
                个性化推荐：基于用户设备信息和服务日志信息，间接提取</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">用户偏好特征*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">，并基于特征标签产生用户人群画像，用户展示、推送以及可能的商业广告；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2） 基础的账号服务：使用您的</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">手机号码*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、拟使用的昵称及密码用户创建起点账号，继而使用需要平台账号才能开通的服务：评论、配音、发帖、分享、签到、活动、上传/更改头像，展示用户获得的公开荣誉；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）
                社交/发布功能：当您在书友圈、本章说发布信息时，我们需要您的个人信息：昵称/头像用于公开内容的发布；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4）
                当用户参与站内举办的活动时，我们需要用户的个人信息：昵称/头像用于抽奖结果、粉丝排行榜及其他公示内容的展示。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.2
                为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息用于我们的其他服务。在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.3
                为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息，您使用应用程序的频率、故障信息、总体使用情况、性能数据以及应用程序的来源。我们不会将我们存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.4
                在您使用我们的产品或服务时，我们可能通过放置安全的Cookie及相关技术收集您的信息，包括：您的登录信息、浏览信息、偏好设置。您也可以通过浏览器设置管理Cookie。但请注意，如果停用Cookie，您可能无法享受最佳的服务体验，某些服务也可能无法正常使用。Cookie
                对提升用户的网络使用体验十分重要，我们使用Cookie一般出于以下目的：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）优化登录体验：
                Cookie可以在您进行注册、登录时，通过存储账号信息，帮您填入您最后登入的账号名称、简化您重复操作的步骤；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）安全：Cookie可帮助我们保障数据和服务的安全性，排查针对我们的产品和服务的作弊、黑客、欺诈行为；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）偏好设置：用来保存用户简单的个性化设置。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.5 信息的分享和披露</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.5.1
                在法律法规规定的情况下，或经您事先明确同意或手动选择，我们可能与第三方分享您的个人信息；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.5.2
                仅为实现外部处理的目的，我们可能会与第三方合作伙伴（第三方服务供应商、承包商、代理、广告合作伙伴、应用开发者，代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">位置*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">服务的地图服务供应商）（他们可能并非位于您所在的法域）分享您的个人信息，让他们按照我们的说明、隐私协议以及其他相关的保密和安全措施来为我们处理上述信息，如我们与上述第三方分享您的信息，我们将会采用加密、匿名化处理等手段保障您的信息安全，并用于以下用途：</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）向您提供我们的服务；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）实现"我们如何使用收集的信息"部分所述目的；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）履行我们在《用户服务协议》中的义务和行使我们的权利；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4）理解、维护和改善我们的服务。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">如我们与上述第三方分享您的信息，我们将会采用加密、匿名化处理手段保障您的信息安全。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.5.3信息的转让</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">我们不会与</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">提供者以外的公司、组织个人转让用户的个人信息，但以下情况除外:</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">&#xa0;</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）随着我们业务的持续发展，当发生合并、收购、资产转让交易导致向第三方分享您的个人信息时，我们将通过推送通知、公告形式告知您相关情形，按照法律法规及不低于本协议所要求的标准继续保护或要求新的管理者继续保护您的个人信息。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.5.4
                我们会将所收集到的信息用于大数据分析。我们将收集到的信息用于分析形成不包含任何个人信息的行业洞察报告。我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.5.5 我们可能基于以下目的披露您的个人信息：</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）遵守适用的法律法规等有关规定；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）遵守法院判决、裁定或其他法律程序的规定；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）遵守相关政府机关或其他法定授权组织的要求；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4）我们有理由确信需要遵守法律法规等有关规定；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（5）为执行相关服务协议或本协议、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.5.6 共享、转让、公开披露个人信息涉及类型</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）网页对外共享、转让、公开披露的个人信息的类型：设备号、网络地域、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">充值/消费金额*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">、阅读书籍、发表评论/想法/配音/发帖、书籍评分、创建的书单、读过书籍数量、关注作者/用户、收藏书籍/专题/书单；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）App对外共享、转让、公开披露的个人信息的类型：广告服务加密提供通过该渠道下载用户设备号、渠道、版本、平台、机型、屏幕分辨率及屏幕大小、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">App</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">应用列表、阅读时长、App使用时长、阅读书籍分类、</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">付费金额*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">3.5.7
                我们已知晓对外共享、转让、公开披露个人信息所承担的相应法律责任，并将采取合理措施保障个人信息安全。</span></p>
        <p style="margin:0pt 0pt 19.5pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">四、【您如何访问和控制自己的个人信息】</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">4.1您对我们产品与/或服务中的您的个人信息享有多种方式的控制权，包括：您可以访问、更正/修改、删除您的个人信息，也可以撤回之前作出的对您个人信息的同意，同时您也可以注销您的账号。为便于您行使您的上述控制权，我们在产品的相关功能页面为您提供了操作指引和操作设置，您可以自行进行操作，如您在操作过程中有疑惑或困难的可以通过文末的方式联系我们来进行控制，我们会及时为您处理。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">. 4.1.1 访问您的个人信息</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                您可以在我们的产品与/或服务中查询或访问您的相关个人信息，包括：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                账号信息：您可以登录您的个人账号，访问-「我」-个人主页访问您的账号中的个人资料信息，包括：头像、昵称、社区简介；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                使用信息：您可以通过书架页、搜索页、我页，随时查阅您的浏览记录、书架添加记录、搜索记录等；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">&#xa0;</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">交易消费信息*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">：您可以通过访问「我」页-点击账户余额，查看您的</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">交易记录*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">及</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">消费记录*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                其他信息：如您在此访问过程中遇到操作问题的或如需获取其他前述无法获知的个人信息内容，您可通过文末提供的方式联系我们，我们将在核实您的身份后在合理期限内向您提供，但法律法规另有规定的或本政策另有约定的除外。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">. 4.1.2更正/修改您的个人信息</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                您可以在我们的产品与/或服务中更正/修改您的相关个人信息。为便于您行使您的上述权利，我们为您提供了在线自行更正/修改和向我们提出更正/修改申请两种方式。出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作指引和操作设置，您可以直接进行更正/修改，操作指引如下：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                （1）“头像/昵称/社区简介”信息的更正/修改界面为：「我」—个人主页—编辑资料；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">. （2）“</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">手机号码*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">/</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">邮箱*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">/</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">密码*</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">/第三方账号”信息的更正/修改界面为：「我」-设置-安全中心；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">. 4.1.3 删除与注销</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                一般而言，我们只会在法律法规规定或必需且最短的时间内保存您的个人信息。为便于您行使您的上述删除权，我们为您提供了在线自行删除和向我们提出删除申请两种方式。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作指引和操作设置，您可以直接进行删除，一旦您删除后，我们即会对此类信息进行删除或匿名化处理，除非法律法规另有规定：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                （1）“搜索历史”信息在App中的删除界面为：书架/精选/发现—搜索-搜索历史；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                （2）“浏览记录”信息在App中的删除界面为：「我」-浏览记录；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                在以下情形下，您可以直接向我们提出删除您个人信息的请求，但已做数据匿名化处理或法律法规另有规定的除外：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">. （1）如果我们处理个人信息的行为违反法律法规；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                （2）如果我们收集、使用您的个人信息，却未征得您的同意；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">. （3）如果我们处理个人信息的行为违反了与您的约定；</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                （4）如果您不再使用我们的产品或服务，或您注销了账号；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">. （5）如果我们不再为您提供产品或服务。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                我们为您提供账号注销权限，您可以通过App中功能操作或联系我们的客服注销您的账号，操作界面：「我」-设置-安全中心-账号注销。一旦您注销账号，将无法使用我们提供的全线用户产品的服务且自动放弃已有的权益，因此请您谨慎操作。除法律法规另有规定外，注销账号之后，我们将停止为您提供我们所有的产品和服务，您曾通过该账号使用的我们的产品与服务下的所有内容、信息、数据、记录将会被删除或匿名化处理。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">. 4.1.4 撤回同意权</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">.
                如您想更改相关功能的授权范围，您可以通过您的设备修改产品管理对应权限授权范围，操作界面：系统设置-应用详情页-权限设置/通知设置。当您取消相关个人信息收集的授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">4.2
                在您访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账号的安全。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">4.3
                请您理解，由于技术所限、法律或监管要求，我们可能无法满足您的所有要求，我们会在合理的期限内答复您的请求。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">4.4个人信息安全保护措施和能力：保护用户个人信息是</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">的一项基本原则，</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">将会采取合理的措施保护用户的个人信息。除法律法规规定或本协议另有约定的情形外，未经用户许可</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">不会向第三方公开、透露用户个人信息。</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">对相关信息采用专业加密存储与传输方式，保障用户个人信息的安全。</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（1）
                数据安全传输方面，采用传输层安全协议等密码技术，通过Https等方式防止传输链路被嗅探、窃听截取风险，建立安全的隐私数据采集环境，保证数据采集的私密性和完整性；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（2）
                数据安全存储方面，对数据进行分类分级处置并对用户个人敏感信息采取独立、加密存储等额外的安全保护措施；</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（3）
                数据访问和使用的安全控制方面，实施严格的数据权限控制机制，采取多重身份认证技术，并对能够处理您的信息的行为进行监控，避免数据被违规访问和未授权使用；</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">&#xa0;</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">（4） 其他实现数据安全保护的措施。</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">4.5仅为实现外部处理的目的、或随着我们业务的持续发展，当发生合并、收购、资产转让交易时，我们可能会与第三方合作伙伴（第三方服务供应商、承</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">包商、代理、广告合作伙伴、应用开发者）（他们可能并非位于您所在的法域）分享您的个人信息，让他们按照我们的说明、隐私协议以及其他相关的保密和安全措施来为我们处理上述信息。如我们与上述第三方分享您的信息，我们将会采用加密、匿名化处理手段保障您的信息安全。</span>
        </p>
        <p style="margin:0pt 0pt 19.5pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">五、【协议更新】</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">变更后的协议，我们将通过站内信、页面提示、标注红点的方式另行向您说明对应信息的收集目的、范围及使用方式，并向您提供自主选择同意的方式，且在征得您明示同时后收集、使用您的个人信息。</span>
        </p>
        <p style="margin:0pt 0pt 19.5pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt; font-weight:bold">六、【联系我们】</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">我们的联系方式：</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">月见初花文化传媒有限公司</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">客服电话: 0</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">28</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">-</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">67877882</span></p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">注册地址：</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">中国（四川）自由贸易试验区成都高新区天府大道中段500号1栋6楼611号</span>
        </p>
        <p style="line-height:18pt; margin:0pt 0pt 15pt"><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">常用办公地址：</span><span
                style="color:#555555; font-family:PingFangSC-Regular; font-size:12pt">中国（四川）自由贸易试验区成都高新区天府大道中段500号1栋6楼611号</span>
        </p>
    </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name:'privacy',
  data(){
    return{}
  },
  methods:{

  }
}
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.privacy{
  width: 1200px;
  margin: 0 auto;
  padding: 50px 220px;
  p{
    font-family: PingFangSC-Regular;
    text-indent: 2em;
    font-size: 16px;
    width: 100%;
        line-height: 32px;
    word-spacing: 2px;
  }
  h2{
    padding-bottom: 15px
  }
}
.privacy_title{
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding: 20px 0;
  @include flex-center();
}
.text{
  width: 100%;
  @include flex-center();
  .box{
    @include flex-column-left() ;
  }
}
</style>


